<template>
  <div>
    <!-- 背景图 -->
    <!-- <div id="bg"></div> -->

    <!-- 头部区域 -->
    <VueHeader></VueHeader>

    <!-- 主体区域 -->
    <VueMain></VueMain>
  </div>
</template>

<script>
// 头部区域
import VueHeader from "./components/VueHeader.vue"; //  头部内容
import VueMain from "./components/VueMain.vue"; // 主体内容

export default {
  // 局部注册
  components: {
    VueHeader,
    VueMain,
  },
};
</script>

<style>
/* 背景图片 */
/* #bg { */
/* position: absolute; */
/* top: 23.438vw; */
/* top: 0; */
/* width: 100%; */
/* height: 100%; */
/* background: url(./assets/image/bg.jpg); */
/* z-index: -999; */
/* } */
</style>