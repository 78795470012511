<template>
  <!-- 负面效果 -->
  <div class="debuff" id="debuff">
    <div class="container">
      <!-- 标题 -->
      <h2 class="h2 section-title">负面效果</h2>

      <!-- 内容 -->
      <ul class="content debuff-list">
        <li>
          <div class="item">
            <!-- 图片 -->
            <div class="img">
              <img src="../assets/image/Debuff-01.png" />
            </div>

            <p>
              被击晕后，无法进行任何操作，如果当前已被击晕，则本次击晕时间覆盖剩余时间，晕眩最小时间为0.1秒。
            </p>
            <br />
            <p>
              萨姆斯（升级的球形态）、索尼克（变身形态）、耀西无视晕眩，耀西和罗莎塔可以解除晕眩效果。
            </p>
            <br />
            <p>打气人的被动减少1.5秒的晕眩时间。</p>
            <br />
            <p>通常，<span class="n">普通难度</span>及以上才会出现晕眩效果。</p>
          </div>
        </li>
        <li>
          <div class="item">
            <!-- 图片 -->
            <div class="img">
              <img src="../assets/image/Debuff-02.png" />
            </div>

            <p>
              这是<span class="k">改造难度</span
              >独有的负面状态，受到诅咒效果后，下一次受伤就会立即死亡。
            </p>
            <br />
            <p>如果当前已拥有诅咒状态，则不会再次受到诅咒效果。</p>
            <br />
            <p>萨姆斯（升级的球形态）、索尼克（变身形态）无视诅咒。</p>
            <br />
            <p>
              罗莎塔的不朽可以在条件内解除诅咒状态，罗莎塔的被动立场不会在诅咒状态下触发。
            </p>
          </div>
        </li>
        <li>
          <div class="item">
            <!-- 图片 -->
            <div class="img">
              <img src="../assets/image/Debuff-03.png" />
            </div>

            <p>
              这是<span class="k">改造难度</span
              >独有的负面状态，被沉默后，除了被动和普通攻击(X)以外的技能均不可使用。
            </p>
            <br />
            <p>桃子无法在举起无敌星时使用普通攻击(X)来投掷无敌星。</p>
            <br />
            <p>罗莎塔的不朽可以在条件内解除沉默状态。</p>
            <br />
            <p>萨姆斯（升级的球形态）、索尼克（变身形态）无视沉默。</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.debuff .container {
  padding-top: 50px;
}

.debuff-list {
  width: max-content;
  margin: 0 auto;
}

.debuff .content .item {
  max-width: 280px;
  background: var(--raisin-black-1);
  color: var(--white);
  padding: 20px 20px;
  margin-bottom: 50px;
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.2);
}

/* debuff 图片 容器*/
.debuff .content .item .img {
  width: 100%;
  text-align: center;
}
/* debuff 图片 */
.debuff .content .item img {
  width: 60px;
  margin-bottom: 20px;
  cursor: pointer;
}

.debuff .content .item h2 {
  margin-bottom: 20px;
}

.debuff .content p {
  text-indent: 2em;
}

/* 响应大于420px */
@media (min-width: 420px) {
  .debuff .content .item {
    max-width: 85vw;
  }
}

/* 响应大于1024px */
@media (min-width: 1024px) {
  .debuff .content .item {
    max-width: 45vw;
  }
  .debuff-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 10px;
  }
}

/* 响应大于1200px */
@media (min-width: 1200px) {
  .debuff .content .item {
    max-width: 31vw;
  }
}
</style>
