<template>
  <!-- 负面效果 -->
  <div class="else" id="else">
    <div class="container">
      <!-- 标题 -->
      <h2 class="h2 section-title">其他分类</h2>

      <!-- 内容 -->
      <ul class="content">
        <!-- 碰撞与体积 -->
        <li>
          <div class="item">
            <h2>碰撞与体积：<img src="../assets/image/collision01.png" /></h2>

            <p>
              在8BIT RAID中，一格的距离为128
              而人物的碰撞体积除了板子均为64，半格碰撞体积判定均为矩形。
            </p>
            <br />

            <p>
              板子的宽度为224，高96。1.75格宽，0.75格高,而一些技能会改变人物的碰撞体积。
            </p>
            <br />

            <div class="table-box has-scrollbar">
              <!-- 表格 -->
              <table class="table table-striped table-hover">
                <!-- 标题 -->
                <thead class="align-middle">
                  <tr>
                    <th>角色名</th>
                    <th>技能名</th>
                    <th>增加宽度(数值)</th>
                    <th>增加高度(数值)</th>
                    <th>宽度(数值)</th>
                    <th>高度(数值)</th>
                    <th>宽度(格数)</th>
                    <th>高度(格数)</th>
                  </tr>
                </thead>

                <tbody class="align-middle">
                  <!-- 板子 -->
                  <tr>
                    <td rowspan="2">vaus(板子)</td>
                    <td>旋转</td>
                    <td></td>
                    <td>+150(+278)</td>
                    <td></td>
                    <td>264(374)</td>
                    <td></td>
                    <td>≈2格(≈3格)</td>
                  </tr>
                  <tr class="">
                    <td>激怒模式</td>
                    <td>+128</td>
                    <td></td>
                    <td>352</td>
                    <td></td>
                    <td>2.75格</td>
                    <td></td>
                  </tr>

                  <!-- MARIO(马里奥) -->
                  <tr>
                    <td>MARIO(马里欧)</td>
                    <td>无敌星</td>
                    <td></td>
                    <td></td>
                    <td>384</td>
                    <td>384</td>
                    <td>3格</td>
                    <td>3格</td>
                  </tr>

                  <!-- AIRMAN(空气人) -->
                  <tr>
                    <td>AIRMAN(空气人)</td>
                    <td>龙卷风形态</td>
                    <td>+256</td>
                    <td>+256</td>
                    <td>320</td>
                    <td>320</td>
                    <td>2.5格</td>
                    <td>2.5格</td>
                  </tr>

                  <!-- DEDEDE(帝帝帝) -->
                  <tr>
                    <td rowspan="2">DEDEDE(帝帝帝)</td>
                    <td>弹幕反射</td>
                    <td>+488</td>
                    <td>+488</td>
                    <td>512</td>
                    <td>512</td>
                    <td>4格</td>
                    <td>4格</td>
                  </tr>
                  <tr>
                    <td>反击</td>
                    <td>+128</td>
                    <td>+128</td>
                    <td>192</td>
                    <td>192</td>
                    <td>1.5格</td>
                    <td>1.5格</td>
                  </tr>

                  <!-- TAIZO(堀泰三) -->
                  <tr>
                    <td>TAIZO(堀泰三)</td>
                    <td>跳跃/跳过地面</td>
                    <td>+256</td>
                    <td>+256</td>
                    <td>320</td>
                    <td>320</td>
                    <td>2.5格</td>
                    <td>2.5格</td>
                  </tr>

                  <!-- LEE(李) -->
                  <tr>
                    <td>LEE(李)</td>
                    <td>金刚不坏</td>
                    <td>+384</td>
                    <td>+384</td>
                    <td>448</td>
                    <td>448</td>
                    <td>3.5格</td>
                    <td>3.5格</td>
                  </tr>

                  <!-- 路易吉 -->
                  <tr>
                    <td>LUIGI(路易吉)</td>
                    <td>星星/防御形态</td>
                    <td>+384</td>
                    <td>+384</td>
                    <td>448</td>
                    <td>448</td>
                    <td>3格</td>
                    <td>3格</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>

        <!-- 移动速度 -->
        <li>
          <div class="item">
            <h2>移动速度</h2>
            <p>
              在8BIT
              RAID中，所有玩家每0.02秒进行一次移动，移动速度为每0.02秒移动的数值。
            </p>
            <br />
            <p>所有角色的基础移动速度为10，耀西因为被动所以是15.</p>
            <br />
            <p>而一些技能会改变人物的移动速度。</p>
            <br />

            <div class="table-box has-scrollbar">
              <!-- 表格 -->
              <table class="table table-striped table-hover">
                <!-- 标题 -->
                <thead class="align-middle">
                  <tr>
                    <th>角色名</th>
                    <th>技能名</th>
                    <th>增加移动速度(数值)</th>
                    <th>加成后移速(数值)</th>
                  </tr>
                </thead>

                <!-- 内容 -->
                <tbody class="align-middle">
                  <!-- 板子 -->
                  <tr>
                    <td>VUAS(板子)</td>
                    <td>旋转</td>
                    <td>+10</td>
                    <td>20</td>
                  </tr>

                  <!-- ROCKMAN(洛克人) -->
                  <tr>
                    <td>ROCKMAN(洛克人)</td>
                    <td>滑行</td>
                    <td>+10</td>
                    <td>20</td>
                  </tr>

                  <!-- PEACH(桃子) -->
                  <tr>
                    <td>PEACH(桃子)</td>
                    <td>无敌星</td>
                    <td>+10</td>
                    <td>20</td>
                  </tr>

                  <!-- MARIO(马里奥) -->
                  <tr>
                    <td>MARIO(马里奥)</td>
                    <td>无敌星</td>
                    <td>+10</td>
                    <td>20</td>
                  </tr>

                  <!-- SONIC(索尼克) -->
                  <tr>
                    <td rowspan="3">SONIC(索尼克)</td>
                    <td>加速</td>
                    <td>+20</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>超级索尼克</td>
                    <td>+10</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>加速(超级索尼克)</td>
                    <td>+30</td>
                    <td>50</td>
                  </tr>

                  <!-- AIRMAN(空气人) -->
                  <tr>
                    <td>AIRMAN(空气人)</td>
                    <td>龙卷风形态</td>
                    <td>+10</td>
                    <td>20</td>
                  </tr>

                  <!-- YOSHI(耀西) -->
                  <tr>
                    <td>YOSHI(耀西)</td>
                    <td>灵活的身体</td>
                    <td>+5</td>
                    <td>15</td>
                  </tr>

                  <!-- SAMUS(萨姆斯) -->
                  <tr>
                    <td>SAMUS(萨姆斯)</td>
                    <td>球形态</td>
                    <td>+5</td>
                    <td>15</td>
                  </tr>

                  <!-- TAIZO(堀泰三) -->
                  <tr>
                    <td>TAIZO(堀泰三)</td>
                    <td>跳跃/跳过地面</td>
                    <td>强制移动速度(25)</td>
                    <td>25</td>
                  </tr>

                  <!-- PIKACHU(皮卡丘) -->
                  <tr>
                    <td>PIKACHU(皮卡丘)</td>
                    <td>利爪</td>
                    <td>+10</td>
                    <td>20</td>
                  </tr>

                  <!-- LEE(李) -->
                  <tr>
                    <td>LEE(李)</td>
                    <td>金刚不坏</td>
                    <td>+10</td>
                    <td>+20</td>
                  </tr>

                  <!-- PENTA(企太) -->
                  <tr>
                    <td>PENTA(企太)</td>
                    <td>企鹅直升机</td>
                    <td>+10</td>
                    <td>+20</td>
                  </tr>

                  <!-- RYU(隼龙) -->
                  <tr>
                    <td>RYU(隼龙)</td>
                    <td>隐步</td>
                    <td>+10</td>
                    <td>+20</td>
                  </tr>

                  <!-- 路易吉 -->
                  <tr>
                    <td rowspan="3">路易吉</td>
                    <td>敏捷</td>
                    <td>+5</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td>星星</td>
                    <td>+5+5(敏捷)</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>防御形态</td>
                    <td>10</td>
                    <td>20</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>

        <!-- 法力恢复 -->
        <li>
          <div class="item">
            <h2>
              法力恢复
              <img src="../assets/image/Mana.png" />
            </h2>

            <p>自然法力恢复为0.02秒检查一次，<br /></p>
            <br />
            <p>
              在旋转(Vaus)，悬浮(Vaus)，翻墙(Taizo)，加速(Soinc)，骑乘模式(Yoshi)，创建传送门(Tetromino)这些状态下不会有自然法力恢复。
            </p>
            <br />
            <p>每个人物都有基础法力恢复50，而耀西是100。</p>
            <br />
            <p>
              多少秒恢复1法力值公式 = 基础法力恢复 * ( 1 - ( 冷却升级等级 * 0.1
              ) )
            </p>
            <br />
            <p>
              塞尔达的时之歌将在使用期间提升10倍的法力恢复速度。（耀西减半计算即可）
            </p>
            <br />

            <div class="table-box has-scrollbar">
              <!-- 表格 -->
              <table class="mana table table-striped table-hover">
                <!-- 标题 -->
                <thead class="align-middle">
                  <tr>
                    <th>冷却升级等级</th>
                    <th>每秒恢复法力值</th>
                    <th>多少秒恢复1法力值</th>
                  </tr>
                </thead>

                <tbody class="align-middle">
                  <!-- 冷却升级等级 -->
                  <tr>
                    <td>0</td>
                    <td>1</td>
                    <td>1s</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>1.11111</td>
                    <td>0.9s</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>1.25</td>
                    <td>0.8s</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>1.42857</td>
                    <td>0.7s</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>1.66666</td>
                    <td>0.6s</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>2</td>
                    <td>0.5s</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>2.5</td>
                    <td>0.4s</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>3.33333</td>
                    <td>0.3s</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>5</td>
                    <td>0.2s</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style scoped>
.else .content {
  width: max-content;
  margin: 0 auto;
}

.else .content li {
  max-width: 280px;
  background: var(--raisin-black-1);
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.2);
  padding: 20px 0;
  margin-bottom: 50px;
  color: var(--white);
}

.else .content li h2 {
  text-align: center;
}

/* 表格容器 */
.table-box {
  overflow-x: auto;
}

/* 表格 */
.else table {
  width: 500px;
  background-color: var(--light-gray);
  text-align: center;
  margin-bottom: 15px;
}

.else th,
.else td {
  /* border: 2px solid #000000; */
  height: 50px;
  padding: 5px;
}

/* 法力恢复 表格 */
/* .else .content .item .mana {
  width: 400px;
} */

/* else 图片 */
.else .content .item img {
  width: 60px;
  cursor: pointer;
}

.else .content li:nth-child(3) img {
  width: 100px;
  cursor: pointer;
}

.else .content .item h2 {
  margin-bottom: 20px;
}

.else .content p {
  text-indent: 2em;
  padding: 0 20px;
}

/* 响应大于420px */
@media (min-width: 420px) {
  .else .content li {
    max-width: 85vw;
  }
}
/* 响应大于575px */
@media (min-width: 575px) {
  /* 表格 */
  .else table {
    width: 100%;
  }
}

/* 响应大于1024px */
@media (min-width: 1024px) {
  .else .content li {
    max-width: 48%;
    height: max-content;
  }

  .else .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 25px;
  }

  .else .content li:nth-child(3) {
    /* margin-top: -30%; */
  }

  /* 表格 */
  .else table {
    width: 100%;
  }
}

/* 响应大于1115px */
@media (min-width: 1115px) {
  .else .content li:nth-child(3) {
    margin-top: -300px;
  }
}

/* 响应大于1200px */
@media (min-width: 1200px) {
  .else .content li {
    max-width: 31%;
  }
  .else .content li:nth-child(3) {
    margin-top: 0;
  }
}
</style>