<template>
  <!-- 名场面 -->
  <div class="famous" id="famous">
    <div class="container">
      <h2 class="h2 section-title">名场面</h2>

      <ul class="famous-list">
        <li>
          <div class="item">
            <img src="../assets/famous/0001.gif" alt="" />
          </div>
        </li>
        <li>
          <div class="item">
            <img src="../assets/famous/0002.gif" alt="" />
          </div>
        </li>
        <li>
          <div class="item">
            <img src="../assets/famous/0003.gif" alt="" />
          </div>
        </li>
        <li>
          <div class="item">
            <img src="../assets/famous/0004.gif" alt="" />
          </div>
        </li>
        <li>
          <div class="item">
            <img src="../assets/famous/0005.gif" alt="" />
          </div>
        </li>
        <li>
          <div class="item">
            <img src="../assets/famous/0006.gif" alt="" />
          </div>
        </li>
        <li>
          <div class="item">
            <img src="../assets/famous/0007.gif" alt="" />
          </div>
        </li>
        <li>
          <div class="item">
            <img src="../assets/famous/0008.gif" alt="" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.famous .container {
  padding: 40px 0;
}

.famous-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  /* scroll-snap-type 设置了在有滚动容器的情形下吸附至吸附点的严格程度 */
  scroll-snap-type: inline mandatory;
  padding-left: 20px;
}

.famous .container li {
  min-width: 90%;
  scroll-snap-align: center;
  margin-bottom: 20px;
}

/* 图片 */
.famous .container img {
  width: 100%;
  height: 150px;
  border: 4px solid #0fd0ff;
  border-radius: 10px;
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, 0.2);
}

.famous-list::-webkit-scrollbar {
  height: 10px;
}

.famous-list::-webkit-scrollbar-button {
  width: 20px;
}

/* 响应大于420px -------------------------------------------------------------------*/
@media (min-width: 420px) {
  /* 图片 */
  .famous .container img {
    height: 250px;
  }
}
/* 响应大于575px -------------------------------------------------------------------*/
@media (min-width: 575px) {
  .famous .container li {
    min-width: 70%;
  }
  .famous-list {
    gap: 25px;
  }
}

/* 响应大于760px -------------------------------------------------------------------*/
@media (min-width: 760px) {
  .famous .container li {
    min-width: 60%;
  }
  .famous-list {
    gap: 35px;
  }
}

/* 响应大于1024px ------------------------------------------------------------------*/
@media (min-width: 1024px) {
  .famous .container li {
    min-width: 45%;
  }
  .famous-list {
    gap: 40px;
  }
}

/* 响应大于1200px ------------------------------------------------------------------*/
@media (min-width: 1200px) {
  .famous .container li {
    min-width: 35%;
  }
  .famous-list {
    gap: 40px;
  }
}

/* 响应大于1500px -------------------------------------------------------------------*/
@media (min-width: 1500px) {
  /* 图片 */
  .famous .container img {
    height: 300px;
  }

  .famous .container li {
    min-width: 30%;
  }
  .famous-list {
    gap: 40px;
  }
}
</style>