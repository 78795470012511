<template>
  <!-- 主要内容 -->
  <main class="main">
    <!-- BOSS技能介绍 -->
    <Boss></Boss>
    <!-- 负面效果 -->
    <debuff></debuff>
    <!-- 其他分类 -->
    <Else></Else>
    <!-- 排行榜 -->
    <RankingList></RankingList>
    <!-- 名场面 -->
    <famous></famous>
  </main>
</template>

<script>
import Boss from "./BossM.vue"; // BOSS介绍
import debuff from "./DebuffM.vue"; // 负面效果
import Else from "./ElseM.vue"; // 其他分类
import RankingList from "./RankingM.vue"; //排行榜
import famous from "./famousV.vue"; //排行榜
export default {
  components: {
    RankingList,
    Boss,
    debuff,
    Else,
    famous,
  },
};
</script>

<style>
.main {
  position: relative;
  background: url(../assets/image/section-wrapper-bg.jpg);
}
</style>